/* index.css */

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Roboto", sans-serif;
}
